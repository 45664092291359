<template>
  <i18n path="title" tag="span" class="pagetitle">
    <span class="ncblue">NC</span>
  </i18n>
</template>
<script>
export default {
  name: 'theme-header'
}
</script>
<style lang="sass"></style>
